import React, { useState, useEffect } from 'react';
import Util from '../../util/Util';
import TileContainer from './TileContainer';

function PuzzleMain(props) {
    const[gameOver, setGameOver] = useState(false);
    //const[initialMap, setInitialMap] = useState();
    const[blankLocation, setBlankLocation] = useState([0,0])
    const[clickCount, setClickCount] = useState(0);
    //function to build intitial map
    function getInitialMap() {
        let map = [];
        let row = []
        for (let r = 0; r < props.rows; r++) {
            row = []
            for (let c = 0; c < props.cols; c++) {
                row.push([r,c]);
            }
            map.push(row)
        }
        return map;
    }
    const initialMap = getInitialMap();
    const[gameMap, setGameMap] = useState(initialMap);
    let ctx = [];
    let offsets = [];
    let videoSource;
    const bodyHeight = document.body.clientHeight;
    const bodyWidth = document.body.clientWidth;

    function handleClick(r, c) {
        if(gameOver){
            return;
        }
        moveTile(r, c);
        checkWin();
    }

    //move tile logic - can handle multiple quick moves without updating the DOM
    function updateMap(r, c, gm, bl) {
        let map = gm
        //tile that was clicked becomes the blank location

        //if blank in same row
         if (r === bl[0]) {
            //move whole row
            if (c < bl[1]) {
                //if blank location is to the right, move all tiles left
                for (let i = bl[1]; i > c; i--) {
                    map[r][i] = [map[r][i-1][0], map[r][i-1][1]];
                }
            } else {
                //if blank location is to the left, move all tiles right
                for (let i = bl[1]; i < c; i++) {
                    map[r][i] = [map[r][i+1][0], map[r][i+1][1]];
                }
            }
        //if blank is same column
        } else if (c === bl[1]) {
            //move whole column
            if (r < bl[0]) {
                //if blank location is above, move all tiles up
                for (let i = bl[0]; i > r; i--) {
                    map[i][c] = [map[i-1][c][0], map[i-1][c][1]];
                }
            } else {
                //if blank location is below, move all tiles down
                for (let i = bl[0]; i < r; i++) {
                    map[i][c] = [map[i+1][c][0], map[i+1][c][1]];
                }
            }
        } 
        //set blank location to the clicked loction
        map[r][c] = [0,0];
        bl = [r, c];

        //return new map and blank location so it can called multiple times without updating
        //the real map and blank location states
        return [map, bl];
    }

    //move tiles based on click location
    function moveTile(r, c) {
        let map = gameMap;

        if (r === blankLocation[0] && c === blankLocation[1]) {
            //if clicking the blank location do nothing
            return;
        } else if (r !== blankLocation[0] && c !== blankLocation[1]) {
            //if clicking on a non-movable row
            return;
        }
        //tile that was clicked becomes the blank location
        let gmBl = updateMap(r, c, map, blankLocation);
        map = gmBl[0];
        setBlankLocation([r,c]);
        //update click count
        setClickCount(clickCount + 1);
        //update game map with new layout
        setGameMap(map);
    }

    //shuffle the puzzle
    function shuffle() {
        let numMoves = 1000;
        let map = gameMap;
        let r;
        let c;
        let gmBl = [];

        //get a random box number based on number of rows and columns
        let ranBox = Util.getRandomInt(0, parseInt(props.rows) + parseInt(props.cols));
    
        //selects a random slide and moves it
        if (ranBox < props.rows) {
            r = ranBox
            c = blankLocation[1];
        } else {
            r = blankLocation[0]
            c = ranBox-props.rows;
        }
        
        gmBl = updateMap(r, c, map, blankLocation);

        for (let i = 0; i < numMoves; i++) {
            //get a random box number based on number of rows and columns
            ranBox = Util.getRandomInt(0, parseInt(props.rows) + parseInt(props.cols));
            //selects a random slide and moves it
            if (ranBox < props.rows) {
                r = ranBox
                c = gmBl[1][1];
            } else {
                r = gmBl[1][0]
                c = ranBox-props.rows;
            }
            
            gmBl = updateMap(r, c, gmBl[0], gmBl[1]);
        }

        //click the tile in the top row of the blank column, then click the very first tile
        //this make the blank location always start at top right
        gmBl = updateMap(0, gmBl[1][1], gmBl[0], gmBl[1]);
        gmBl = updateMap(0, 0, gmBl[0], gmBl[1]);

        setGameMap(gmBl[0]);
        setBlankLocation(gmBl[1]);
        setClickCount(0);
    }
    
    //checks win condition
    function checkWin() {
        //console.log(gameMap);
        //console.log(initialMap);
        if (JSON.stringify(gameMap) === JSON.stringify(initialMap)) {
            //if win condition is met, show new game button, stop clicks, and remove borders from slides
            setGameOver(true);
        }
        return;
    }

    //restart the puzzle
    function restartPuzzle() {
        setGameOver(false);
        setClickCount(0);
        shuffle();
    }

    function getCtx() {
        return new Promise(resolve => {
            let canvases = document.getElementsByClassName('image');
            for (let i = 0; i < canvases.length; i++) {
                ctx[i] = canvases[i].getContext('2d');
            }
            resolve(ctx);
        })
    }

    function getOffsets() {
            let vidRatio = videoSource.videoWidth / videoSource.videoHeight;
            let bodyH = parseFloat(bodyHeight);
            let bodyW = parseFloat(bodyWidth);
            let vidH = bodyH;
            let vidW = bodyH * vidRatio;
            let offsetX = 0;
            let offsetY = 0;

            //console.log([videoSource, vidRatio, bodyH, bodyW, vidH, vidW])

            if (vidW >= bodyW) {
                offsetX = (bodyW - vidW) / 2;
            } else {
                vidW = bodyW;
                vidH = bodyW / vidRatio;
                offsetY = (bodyH - vidH) / 2;
            }
            //need to return width and hieght offsets
            return [offsetX, offsetY, vidW, vidH];
            
    }

    function render() {
        for(let i = 0; i < ctx.length; i++) {
            ctx[i].drawImage(videoSource, offsets[0], offsets[1], offsets[2], offsets[3]);
        }
        window.requestAnimationFrame(render);
    }

    useEffect(() => {
        if (props.puzzleType === 'video') {
            // eslint-disable-next-line
            videoSource = document.getElementById('videoSource');
            videoSource.addEventListener( "loadedmetadata", function () {
                getCtx();
                // eslint-disable-next-line
                offsets = getOffsets();
                render();
            }, false );
        }
        let map = Array.from(initialMap);
        setGameMap(map)
        shuffle();
    },[])

    //build puzzle based on map
    let puzzle = [];
    const rowStyle = {height: (100/props.rows)+'%'};
    if (gameMap) {
        for (let r=0; r < props.rows; r++) {
            let tiles = [];
            for (let c=0; c < props.cols; c++) {
                tiles.push(<TileContainer
                    key={'cont-R'+r+'C'+c}
                    row={gameMap[r][c][0]}
                    col={gameMap[r][c][1]}
                    rows={props.rows}
                    cols={props.cols}
                    src={props.src}
                    puzzleType={props.puzzleType}
                    videoRef={props.videoRef}
                    gameOver={gameOver}
                    onClick={handleClick}
                />)
            }
            puzzle.push(
                <div id={'R'+ r} key={'R'+ r} className='row' style={rowStyle}>
                    {tiles}
                </div>
            )
        }
    }

    //maintain click counter element
    let clicks = (
        <div id="clickCount">Clicks: {clickCount}</div>
    )

    //retry and back to menu buttons to be shown on gameover
    let gameOverButtons = (
        <div id="gameOverButtons" className="buttonContainer">
            <div id="button-backToMenu" className="Button" onClick={props.backToMenu}>
                <span className="material-icons" style={{color:'#FF8CD7'}}>arrow_back</span>
            </div>
            <div id="button-restartPuzzle" className="Button" onClick={restartPuzzle}>
                <span className="material-icons">refresh</span>
            </div>
        </div>
    );

    let vidElement;
    if (props.puzzleType === 'video') {
        vidElement = (
            <div id='videoContainer'>
                <video id='videoSource' src={props.src} autoPlay muted loop>Puzzle Video</video>
            </div>
        );
    }

    return (
        <div style={{height:'100%', width:'100%'}}>
            <div id='puzzleMain' className='puzzleMain'>
                {puzzle}
            </div>
            {vidElement}
            {gameOver && gameOverButtons}
            {clicks}
        </div>
    );
}

export default PuzzleMain;