import './PuzzleMenu.css';
import React, {useState, useEffect} from 'react';
import PuzzleMain from '../PuzzleMain';
import FullscreenPreviewContainer from '../FullscreenPreview/FullScreenPreviewContainer';
import ImageGallery from '../ImageGallery/ImageGallery';
import Util from '../../../util/Util';
import LoadingPopup from '../../LoadingPopup/LoadingPopup';
//setting as a global variable so it isn't lost and I don't have to refetch list everytime
let redditPosts = [];

function PuzzleMenu(props) {
  const[rows, setRows] = useState(4);
  const[cols, setCols] = useState(4);
  const[subReddit, setSubReddit] = useState('wallpapers');
  const[puzzleType, setPuzzleType] = useState('image');
  const[puzzlePage, setPuzzlePage] = useState('puzzleMenu');
  //set main src and temp src for updating url in input box
  const[src, setSrc] = useState('https://cdnb.artstation.com/p/assets/images/images/024/538/827/original/pixel-jeff-clipa-s.gif?1582740711');
  const[tempSrc, setTempSrc] = useState('https://cdnb.artstation.com/p/assets/images/images/024/538/827/original/pixel-jeff-clipa-s.gif?1582740711');
  const[previewVisible, setPreviewVisible] = useState(false);
  const[showLoading, setShowLoading] = useState(false);
  const[fullscreenVisible, setFullscreenVisible] = useState(false);
  const[galleryImageVisible, setGalleryImageVisible] = useState(false);
  const[imageNum, setImageNum] = useState();

  //change to puzzle page and hide main logo
  function generatePuzzle() {
    props.setLogoVisible(false);
    setPuzzlePage('puzzle');
  }

  //function for back button
  function backToMenu() {
    props.setLogoVisible(true);
    setPuzzlePage('puzzleMenu');
  }

  //handles response from testing an unknown src to see if it's an image or not
  function imageSrc (src, result) {
    if (result === 'success') {
      setPuzzleType('image');
    } else {
      setPuzzleType('video');
    }
    setSrc(src);
  }

  //handle enter key on the subreddit input field - runs reddit search
  function clickRandomImage(e) {
    if (e.key === 'Enter') {
      getRandomImage();
    }
  }

  //handl enter on url field - shows preview image
  function clickUrlField(e) {
    if (e.key === 'Enter') {
      setSrc(tempSrc);
      setPreviewVisible(true);
    }
  }

  //main function to handle input change in the form
  function handleChange(e) {
      if (e.target.id === 'rows') {
          setRows(e.target.value);
      } else if (e.target.id === 'cols') {
          setCols(e.target.value);
      } else if (e.target.id === 'subReddit') {
          setSubReddit(e.target.value);
          //set redditPosts back to an empty array to it will force the search to run again with new subreddit
          redditPosts = [];
      } else if (e.target.id === 'local') {
          const files = e.target.files[0];
          const fileReader = new FileReader();
          fileReader.readAsDataURL(files);
          fileReader.addEventListener("load", function () {
              setSrc(this.result);
              setTempSrc(this.result);
          });
      } else if (e.target.id === 'url') {
          //input in text box updates the tempSrc, real one is updated on blur
          setTempSrc(e.target.value);
      }
  }

  //gets a random image from reddit based on the input subreddit
  async function getRandomImage() {
    try {
      setShowLoading(true);
      //send request to update subreddit with new links
      await fetch(
        'https://faithful-soapy-shrew.glitch.me/update/links/' + subReddit,
        {
          method: 'POST',
          headers: {}
        });
      //send request to get a random link from the subreddit
      const response = await fetch(
        'https://faithful-soapy-shrew.glitch.me/link/' + subReddit,
        {
          headers: {}
        });
      const jsonResponse = await response.json();
      setShowLoading(false);
      setTempSrc(jsonResponse.data.link);
      setSrc(jsonResponse.data.link);
      setPreviewVisible(true);
    } catch {
        console.log('reddit search failed');
        setShowLoading(false);
    }
}

  //gets random images from reddit based on the input subreddit to be paged through
  async function getNumImage(num) {
    //if num is outside of valid range, return with nothing
    const len = redditPosts.length > 0 ? redditPosts.length : 50;

    if (num === undefined || num === null || num < 0 || num >= len) {
      return;
    }
    //don't run the search again if we still have an array of links
    if (redditPosts.length > 0) {
      let url = redditPosts[num].url;
      setTempSrc(url);
      setSrc(url);
    } else {
      //if redditPosts is empty try to search again and show loading pop-up
      setShowLoading(true);    
      try {
        setShowLoading(true);
        //send request to get 100 random links from subreddit
        const response = await fetch(
          'https://faithful-soapy-shrew.glitch.me/links/' + subReddit,
          {
            headers: {}
          });
        const jsonResponse = await response.json();
        redditPosts = jsonResponse.data;
        setShowLoading(false);
      } catch {
          console.log('reddit search failed');
          setShowLoading(false);
      }
      setShowLoading(false);

      if (redditPosts.length > 0) {
        let url = redditPosts[num].url;
        setTempSrc(url);
        setSrc(url);
      }
    }
  }

  //controls display of the imput image and the video/image icon shown on the generate puzzle button
  let buttonImage;
  let previewImage;
  if (puzzleType === 'image') {
    buttonImage = <span className="material-icons" id="puzzleTypeIcon">image</span>;
    previewImage = <img id='previewImage' src={src} alt='Preview of the random pic.' />;
  } else {
    buttonImage = <span className="material-icons" id="puzzleTypeIcon" style={{color: "#FF8CD7"}}>movie</span>;
    previewImage = <video id='previewImage' src={src} alt='Preview of the random vid.' muted autoPlay loop>Video Unsupported.</video>;
  }

  //show loading screen when making reddit request
  let loadScreen = <LoadingPopup message="Preview Loading..." visible={showLoading} />

  //checking various inputs to figure out if the src is an image or video
  useEffect(() => {
    if (src.startsWith('data:video') || src.toLowerCase().endsWith('mp4') || src.toLowerCase().endsWith('wmv') || src.toLowerCase().endsWith('gifv')) {
      setPuzzleType('video');
      //console.log('sets vid based on src');
    } else if (src.startsWith('data:image') || src.toLowerCase().endsWith('jpg') || src.toLowerCase().endsWith('jpeg') || src.toLowerCase().endsWith('png') || src.toLowerCase().endsWith('gif')) {
      setPuzzleType('image');
      //console.log('sets image based on src');
    } else {
      //last ditch effort to check using a function i found online...
      Util.testImage(src,imageSrc)
      //console.log('failed last 3 char check');
    }
  },[src])

  useEffect(() => {
    //console.log(imageNum);
    getNumImage(imageNum);
    // eslint-disable-next-line
  },[imageNum]);

  //setting the body content to the menu form or the puzzle once generated
  let body;
  if (puzzlePage==='puzzle') {
      body = <PuzzleMain  rows={rows} cols={cols} src={src} puzzleType={puzzleType} generatePuzzle={generatePuzzle} backToMenu={backToMenu} />
  } else {
      body = (
          <div id='menuMain'>
            {loadScreen}
            <div id='title'>
              Slider Puzzle
            </div>
            <div id='secretButton' onClick={()=>{
              //props.setLogoVisible(false);
              getNumImage(0);
              setGalleryImageVisible(true);
            }}></div>
            {galleryImageVisible && <ImageGallery
              setVisible={setGalleryImageVisible}
              setFullscreenVisible={setFullscreenVisible}
              setGalleryVisible={setGalleryImageVisible}
              setImageNum={setImageNum}
              images={redditPosts}
            />}
            {fullscreenVisible && <FullscreenPreviewContainer
              src={src}
              puzzleType={puzzleType}
              setVisible={setFullscreenVisible}
              setGalleryVisible={setGalleryImageVisible}
              imageNum={imageNum}
              setImageNum={setImageNum}
            />}
            {previewVisible && previewImage}
            <div id="form">
              <div id="form-imageSelection" className="form">
              <div className="formTitle"><span className="formTitleSpan">select<span className="altColor">image</span></span></div>
                <label htmlFor="url">paste URL:</label>
                <div>
                  <input type="text" id="url" name="url" value={tempSrc} onChange={handleChange} onBlur={()=>{setSrc(tempSrc);setPreviewVisible(false)}} onKeyUp={clickUrlField} autoComplete="off" />
                  <div className="Button" id='button-imagePreview' onMouseEnter={()=>setPreviewVisible(true)} onMouseLeave={()=>setPreviewVisible(false)}>
                    <span className="material-icons">visibility</span>
                  </div>
                </div>
                <label htmlFor="subReddit" id="subLabel">or get random URL from subreddit:</label>
                <div>
                  <input type="text" id="subReddit" name="subReddit" value={subReddit} onChange={handleChange} onKeyUp={clickRandomImage} onBlur={()=>{setPreviewVisible(false)}} autoComplete="off" />
                  <div className="Button" id='button-getRandomImage' onClick={getRandomImage} onMouseLeave={()=>setPreviewVisible(false)}><span className="material-icons">refresh</span></div>
                </div>
                <label htmlFor="local">or select local:</label>
                <input type="file" id="local" name="local" accept='image/*, video/*' onChange={handleChange} />
              </div>
              <div id="form-puzzleSize" className="form">
              <div className="formTitle"><span className="formTitleSpan">select<span className="altColor">size</span></span></div>
                <label htmlFor="rows">number of Rows: {rows}</label>
                <input type="range" id="rows" name="rows" min="2" max="10" value={rows} onChange={handleChange} />
                <label htmlFor="cols">number of Columns: {cols}</label>
                <input type="range" id="cols" name="cols" min="2" max="10" value={cols} onChange={handleChange} />
            </div>
            </div>
            <div className="Button" id='generatePuzzle' onClick={generatePuzzle}>Generate<span>Puzzle</span> {buttonImage}</div>
          </div>
      );
  }
  
  return body;
}

export default PuzzleMenu;