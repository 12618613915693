import React from 'react';
import './FullscreenPreview.css';
import SmallButtons from '../../Buttons/SmallButtons';

function FullscreenPreview(props) {
    let img;
    if (props.puzzleType === 'image') {
        img = (
            <img id="fsPreviewImage" src={props.src} style={props.imageStyle} alt='full screen preview' />
        )
    } else {
        img = (
            <video id="fsPreviewImage" src={props.src} style={props.imageStyle} muted autoPlay loop >File not found.</video>
        )
    }

    const forwardButton = {
        id: 'previewForward',
        icon: 'arrow_forward',
        onClick: props.onClick,
    }

    const backButton = {
        id: 'previewBack',
        icon: 'arrow_back',
        onClick: props.onClick
    }

    const closePreview = {
        id: 'previewClose',
        icon: 'close',
        onClick: props.onClick
    }

    const selectImage = {
        id: 'selectImage',
        icon: 'done',
        onClick: props.onClick
    }

    const restartImage = {
        id: 'restartPreview',
        icon: 'first_page',
        onClick: props.onClick
    }

    const toggleZoom = {
        id: 'toggleZoom',
        icon: 'fullscreen',
        onClick: props.onClick
    }

    return(
        <div id='fullscreenPreview'>
            <SmallButtons buttons={closePreview} location="top right" opacity="0.5" />
            <SmallButtons buttons={forwardButton} location="right" opacity="0.5" />
            <SmallButtons buttons={backButton} location="left" opacity="0.5" />
            <SmallButtons buttons={[selectImage, restartImage, toggleZoom]} location="bottom" opacity="0.5" horizontal />
            {img}
        </div>
    );
}

export default FullscreenPreview;