import React from 'react';
import GoogleLogin, { GoogleLogout } from 'react-google-login';
import './LoginPopup.css';

function LoginPopup(props) {
    function handleSuccess(googleData) {
      props.setGoogleProfile(googleData);
      props.setLoggedIn(true);
      setTimeout(closePopup, 100);
    }

    function handleFailure(googleData) {
      console.log('login failed');
      console.log(googleData);
    }

    function logout() {
      props.setLoggedIn(false);
      props.setGoogleProfile({tokenId:''});
    }

    function closePopup() {
      props.setLoginVisible(false);
    }

    let googleLogin;
    if (props.loggedIn) {
      googleLogin = (
        <GoogleLogout
          clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
          buttonText="Logout"
          onLogoutSuccess={logout}
        />
      );
    } else {
      googleLogin = (
        <GoogleLogin
          clientId='974977696746-n1p6muqlm3v6v686biusbkffo5e39q9e.apps.googleusercontent.com'
          buttonText="Log in with Google"
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          isSignedIn={true}
          cookiePolicy={'single_host_origin'}
        />
      );
    }

    return (
      <>
        <div id="blackout" onClick={closePopup}></div>
        <div id="loginPopup">
          <p id="loginText">
            Login with Google to keep track of high scores... <br />when I add highscores. 
          </p>
          {googleLogin} <br />
          <div className="Button" id='backButton' onClick={closePopup}><span className="material-icons">arrow_back</span></div>
        </div>
      </>
    );
}

export default LoginPopup;