import './App.css';
import React, { useState, useEffect } from 'react';
import logo from './images/ksvpnLogo512.png';
import PuzzleMenu from './components/SliderPuzzle/PuzzleMenu/PuzzleMenu';
import AdminPage from './components/AdminPage/AdminPage';
import LoginPopup from './components/LoginPopup/LoginPopup';
import ImageGallery from './components/SliderPuzzle/ImageGallery/ImageGallery';
import AllButtons from './components/Buttons/AllButtons';
import SmallButtons from './components/Buttons/SmallButtons';

//main app landing page
function App() {
  const[page, setPage] = useState('puzzleMenu');
  const[googleProfile, setGoogleProfile] = useState({tokenId:''});
  const[loggedIn, setLoggedIn] = useState(false);
  const[logoVisible, setLogoVisible] = useState(true);
  const[loginVisible, setLoginVisible] = useState(false);
  const[adminLoggedIn, setAdminLoggedIn] = useState(false);
  const[adminButtonIcon, setAdminButtonIcon] = useState('admin_panel_settings');
  const[highScoresButtonIcon, setHighScoresButtonIcon] = useState('stars');

  const logoImg = (
      <img src={logo} className="App-logo" alt="logo" />
  );

  //select content to show based on page state
  let body;
  if (page === 'puzzleMenu') {
    body = <PuzzleMenu setLogoVisible={setLogoVisible} setPage={setPage} admin={adminLoggedIn} />;
  } else if (page === 'adminPage') {
    body = <AdminPage setPage={setPage} googleToken={googleProfile.tokenId} />
  } else if (page === 'imageGallery') {
    body = <ImageGallery />
  } else if (page === 'highScores') {
    body = (
      <div>
        High scores page is under development...
      </div>
    );
  } else {
    body = (
      <div>
        You're not supposed to be here...
        <AllButtons />
      </div>
    );
  }

  function handlePageButton(id) {
    switch (id) {
      case 'openLogin':
        setLoginVisible(true);
        break;
      case 'openHighScores':
        if (page !== 'highScores') {
          setPage('highScores');
          setAdminButtonIcon('admin_panel_settings');
          setHighScoresButtonIcon('arrow_back');
        } else {
          setPage('puzzleMenu');
          setHighScoresButtonIcon('stars');
        }
        break;
      case 'openAdminPage':
        if (page !== 'adminPage') {
          setPage('adminPage');
          setAdminButtonIcon('arrow_back');
          setHighScoresButtonIcon('stars');
        } else {
          setPage('puzzleMenu');
          setAdminButtonIcon('admin_panel_settings');
        }
        break;
      default:
        break;
    }
  }

  const menuButtons = [
    { id: 'openHighScores', icon: highScoresButtonIcon, onClick: handlePageButton },
    { id: 'openLogin', icon: 'login', onClick: handlePageButton },
  ];

  if (adminLoggedIn) {
    menuButtons.unshift({ id: 'openAdminPage', icon: adminButtonIcon, onClick: handlePageButton });
  }

  useEffect(() => {
    //console.log(googleProfile.googleId);
    if(googleProfile.googleId === "118077558094115473153") {
      setAdminLoggedIn(true);
    } else {
      setAdminLoggedIn(false);
    }
  }, [googleProfile]);

  return (
    <div className="App" id='app'>
    <main className="App-main">
      {logoVisible && logoImg}
      {logoVisible && <SmallButtons
        buttons={menuButtons}
        location="top right"
        horizontal
      />}
      {body}
      {loginVisible && <LoginPopup
        setGoogleProfile={setGoogleProfile}
        setLoginVisible={setLoginVisible}
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
      />}
    </main>
    </div>
  );
}

export default App;