import React, { useState } from 'react';
import JsonTable from '../JsonTable/JsonTable';
import LoadingPopup from '../LoadingPopup/LoadingPopup';
import './AdminPage.css';

function AdminPage(props) {
    const[json, setJson] = useState({message: 'response will be shown here'});
    const[sqlQuery, setSqlQuery] = useState('SELECT Subreddits.id, Links.subreddit, COUNT(*) AS "count" FROM Links JOIN Subreddits on Links.subreddit=Subreddits.name GROUP BY 2 ORDER BY 3 DESC;');
    const[subredditsToUpdate, setSubredditsToUpdate] = useState('wallpapers, gifs');
    const[apiPath, setApiPath] = useState('POST/cleanup/wallpapers');
    const[loading, setLoading] = useState(false);

    async function handleClick(targetId) {
        switch (targetId) {
            case 'sqlQuery':
                //console.log('sending db request');
                setLoading(true);
                await dbRequest(sqlQuery);
                setLoading(false);
                //console.log('db request complete');
                break;
            case 'updateSubreddits':
                //console.log('updating subreddits');
                setLoading(true);
                await updateSubreddits(subredditsToUpdate);
                setLoading(false);
                //console.log('subreddits updated');
                break;
            case 'updateAllSubreddits':
                //console.log('updating subreddits');
                setLoading(true);
                await updateAllSubreddits();
                setLoading(false);
                //console.log('subreddits updated');
                break;
            case 'testApi':
                //console.log('updating subreddits');
                setLoading(true);
                await sendApiTest();
                setLoading(false);
                //console.log('subreddits updated');
                break;
            default:
                break;
        }
    }

    function handleChange(e) {
        switch (e.target.id) {
            case 'sqlQuery':
                setSqlQuery(e.target.value);
                break;
            case 'updateSubreddits':
                setSubredditsToUpdate(e.target.value);
                break;
            case 'testApi':
                setApiPath(e.target.value);
                break;
            default:
                break;
        }
    }

    function handleEnter(e) {
        if (e.key === 'Enter') {
            handleClick(e.target.id)
            e.preventDefault();
            return false;
        }
    }

    async function dbRequest(sqlQuery) {
        try {
            const body = JSON.stringify({token: props.googleToken, sql: sqlQuery});
            const response = await fetch(
                'https://faithful-soapy-shrew.glitch.me/database', {
                method: 'POST',
                headers: {},
                body: body
              }
            );
            const jsonResponse = await response.json();
            setJson(jsonResponse);
            return jsonResponse;
        } catch {
            console.log('post failed');
        }
    }

    async function updateSubreddits(subreddits) {
        subreddits = subreddits.replace(/\s+/g, '');
        const subredditsArr = subreddits.split(',');
        const responseObj = {};
        for (let sub in subredditsArr) {
            try {
                //console.log('updating ' + subredditsArr[sub]);
                const response = await fetch(
                'https://faithful-soapy-shrew.glitch.me/update/links/' + subredditsArr[sub],
                {
                    method: 'POST',
                    headers: {}
                });
                const jsonResponse = await response.json();
                responseObj[subredditsArr[sub]] = jsonResponse;
            } catch {
              console.log('reddit search failed');
            }
        }
        setJson(responseObj);
    }

    async function updateAllSubreddits() {
        let subNames = '';
        const response = await dbRequest('SELECT * FROM Subreddits;');
        if (response.table) {
            for (let r in response.table) {
                //console.log(response.table[r]['name']);
                subNames += ',' + response.table[r]['name'];
            }
            //console.log(subNames);
            subNames = subNames.substring(1);
            await updateSubreddits(subNames);
        }
    }

    async function sendApiTest() {
        const method = apiPath.substring(0, apiPath.indexOf('/'));
        const path = 'https://faithful-soapy-shrew.glitch.me' + apiPath.substring(apiPath.indexOf('/'));
        //console.log(method);
        //console.log(path);
        try {
            const response = await fetch(
            path,
            {
                method: method,
                headers: {}
            });
            const jsonResponse = await response.json();
            setJson(jsonResponse);
        } catch {
          console.log('API Test Failed');
        }
    }

    let body;
    if (json.table) {
        body =  <JsonTable json={json.table} />;
    } else {
        body = (
            <div id="noTable">
                {JSON.stringify(json)}
            </div>
        );
    }

    return (
        <div id='adminPage'>
            <div id="title">Admin Tools</div>
            <LoadingPopup message="Awaiting Response..." visible={loading} />
            <form>
                <label htmlFor="sqlQuery">SQL Query: </label>
                <input
                    type="text"
                    name="sqlQuery"
                    id="sqlQuery"
                    value={sqlQuery}
                    onChange={handleChange}
                    onKeyPress={handleEnter}
                    autoComplete="off"
                />
                <div
                    className="Button"
                    id="submitSql"
                    onClick={()=>handleClick('sqlQuery')}
                >
                    <span className="material-icons">arrow_forward</span>
                </div>
            </form>
            <form>
                <label htmlFor="updateSubreddits">Subreddits: </label>
                <input
                    type="text"
                    name="updateSubreddits"
                    id="updateSubreddits"
                    value={subredditsToUpdate}
                    onChange={handleChange}
                    onKeyPress={handleEnter}
                    autoComplete="off"
                />
                <div
                    className="Button"
                    id="submitDbUpdate"
                    onClick={()=>handleClick('updateSubreddits')}
                >
                    <span className="material-icons">arrow_forward</span>
                </div>
            </form>
            <form>
                <label htmlFor="testApi">API Path: </label>
                <input
                    type="text"
                    name="testApi"
                    id="testApi"
                    value={apiPath}
                    onChange={handleChange}
                    onKeyPress={handleEnter}
                    autoComplete="off"
                />
                <div
                    className="Button"
                    id="testApiButton"
                    onClick={()=>handleClick('testApi')}
                >
                    <span className="material-icons">arrow_forward</span>
                </div>
            </form>
            <form>
                <label>Update All </label>
                <div
                    className="Button"
                    id="updateAll"
                    onClick={()=>handleClick('updateAllSubreddits')}
                >
                    <span className="material-icons">refresh</span>
                </div>
            </form>
            <span className="altColor">API Response:</span><br/>
            <div id="response">
                {body}
            </div>
        </div>
    );
}

export default AdminPage;