import React, { useState } from 'react';
import FullscreenPreview from './FullscreenPreview';

function FullscreenPreviewContainer(props) {
    const[overlayVisible] = useState(false);
    const[imageOF, setImageOF] = useState('cover');

    function handleClick(id) {
        //console.log(e);
        const imageNum = parseInt(props.imageNum);
        switch (id) {
            case 'previewClose':
                props.setVisible(false);
                break;
            case 'previewBack':
                if (props.imageNum !== 0) {
                    props.setImageNum(imageNum - 1)
                }
                break;
            case 'toggleZoom':
                if (imageOF === 'cover') {
                    setImageOF('contain');
                } else {
                    setImageOF('cover');
                }
                break;
            case 'restartPreview':
                props.setImageNum(0);
                break;
            case 'previewForward':
                props.setImageNum(imageNum + 1);
                break;
            case 'selectImage':
                props.setVisible(false);
                props.setGalleryVisible(false);
                break;
            default:
                break;
        }

    }

    const imageStyle = {
        objectFit: imageOF,
        height: '100%',
        width: '100%'
    }

    let body = (
            <FullscreenPreview
                src={props.src}
                closeVisible={overlayVisible}
                imageStyle={imageStyle}
                onClick={handleClick}
                puzzleType={props.puzzleType}
            />
        )
    
    return body;
}

export default FullscreenPreviewContainer;