import './LoadingPopup.css';
import React, { useEffect, useState } from 'react';

//a reusable pop-up loading windw
function LoadingPopup(props) {
    const[visible, setVisible] = useState(false);
    let body = <div id='loadingPopup'>{props.message}</div>;

    useEffect(() => {
        let timer;
        //when set to visible it starts a timer so it is not shown on short load times
        if (props.visible) {
            //console.log('timer started');
            timer = setTimeout(() => {
                //console.log('timer complete');
                setVisible(true);
            }, 500);
        } else {
            //console.log('timer canceled');
            setVisible(false);
        }
        return () => {clearTimeout(timer)};
    }, [props.visible])


    return visible && body;
}

export default LoadingPopup;