import React, {useState} from 'react';
import Tile from './Tile';

function TileContainer(props) {
    const[row] = useState(props.row);
    const[col] = useState(props.col);
    const id = 'cont-R'+props.row+'C'+props.col;

    let style;
    if(props.gameOver) {
        style = {
            width: (100/props.cols)+'%',
        }
    } else {
        style = {
            width: (100/props.cols)+'%',
            border: '1px solid black'
        }
    }
    
    return(
        <div className='tileContainer' id={id} style={style} onClick={() => props.onClick(row, col)}>
            <Tile
                row={props.row}
                col={props.col}
                rows={props.rows}
                cols={props.cols}
                src={props.src}
                puzzleType={props.puzzleType}
                gameOver={props.gameOver}
                videoRef={props.videoRef}
            />
        </div>
    );
}

export default TileContainer;