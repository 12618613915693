//This is an example componenet to see all different buttons
import './Buttons.css';
import React from 'react';
import SmallButtons from './SmallButtons';

function AllButtons() {
    function onClick(id) {
        console.log('clicked ' + id);
    }

    const highScoresButton = { id: 'openHighScores', icon: 'stars', onClick: onClick };

    const adminPageButton = { id: 'openAdminPage', icon: 'admin_panel_settings', onClick: onClick };

    const buttons = [highScoresButton, adminPageButton];

    const button = {
        icon: 'extension',
        id: 'puzzleButton2',
        onClick
    }

    return (
        <div id="allButtons">
            <SmallButtons buttons={button} location="right" />
            <SmallButtons buttons={buttons} location="top left" />
            <SmallButtons buttons={buttons} location="left" />
        </div>
    );
}

export default AllButtons;