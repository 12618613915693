import './JsonTable.css';
import React from 'react';

function JsonTable(props) {
    function createTable (json) {
        const headerNames = Object.keys(json[0]);
        const header = [];
        const rows = [];
        
        const headerRow = [];
        for (let h in headerNames) {
            headerRow.push(<th key={"header" + h}>{headerNames[h]}</th>);
        }
        header.push(<tr key="headerRow">{headerRow}</tr>);

        for (let r in json) {
            const row = [];
            for (let i in json[r]) {
                if (i === 'url') {
                    row.push(<td key={`row${r}key_${i}`}><a href={json[r][i]} target="_blank" rel="noopener noreferrer">{json[r][i]}</a></td>);
                } else {
                    row.push(<td key={`row${r}key_${i}`}>{json[r][i]}</td>);
                }
            }
            rows.push(<tr key={"row"+r}>{row}</tr>);
        }

        return (
            <table>
                <thead>{header}</thead>
                <tbody>{rows}</tbody>
            </table>
        );
    }

    return createTable(props.json);
}

export default JsonTable;