import React from 'react';

function Tile(props) {
    const id = 'tile-R'+props.row+'C'+props.col;
    const app = document.getElementById('app');
    const w = app.clientWidth;
    const h = app.clientHeight;
    let xMargin = -props.col*(w/props.cols);
    let yMargin = -props.row*(h/props.rows);
    let style = {};
    let canvasStyle= {};

    if (props.row === 0 && props.col === 0 && !props.gameOver) {
        //console.log(props.gameOver);
        style = {
            width: w,
            height: h,
            objectFit: 'cover',
            margin: yMargin + 'px 0 0 ' + xMargin + 'px',
            opacity: 0
        }
        canvasStyle = {
            margin: yMargin + 'px 0 0 ' + xMargin + 'px',
            opacity: 0
        };
    } else {
        style = {
            width: w,
            height: h,
            objectFit: 'cover',
            margin: yMargin + 'px 0 0 ' + xMargin + 'px'
        }
        canvasStyle = {
            margin: yMargin + 'px 0 0 ' + xMargin + 'px'
        };
    }

    let body;
    if (props.puzzleType === 'image') {
        body = <img className='image' id={id} style={style} src={props.src} alt='A puzzle tile.' />
    } else {
        body = (
            <canvas
                className='image'
                id={id}
                style={canvasStyle}
                width={w}
                height={h}
                alt='A puzzle tile.'
            />
        )
    }

    return body;
}

export default Tile;