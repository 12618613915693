import React from 'react';
import './ImageGallery.css';
import SmallButtons from '../../Buttons/SmallButtons';

function ImageGallery(props) {
    const images = [];

    const backButton = {
        id: 'closeImageGallery',
        icon: 'close',
        onClick: ()=>{
            props.setVisible(false);
            props.setFullscreenVisible(false);
        }
    }

    for (let i in props.images) {
        if (props.images[i].url.toLowerCase().endsWith('mp4') || props.images[i].url.toLowerCase().endsWith('wmv') || props.images[i].url.toLowerCase().endsWith('gifv')) {
            images.push(
                <li key={"image" + i}>
                    <video
                        className="galleryImage"
                        src={props.images[i].url}
                        loading="lazy"
                        muted={true}
                        autoPlay={true}
                        loop={true}
                        onClick={() => {
                            props.setImageNum(i)
                            props.setFullscreenVisible(true);
                        }}
                    >error loading video</video>
                </li>
            );
        } else {
            images.push(
                <li key={"image" + i}>
                    <img
                        className="galleryImage"
                        src={props.images[i].url}
                        loading="lazy"
                        alt="..."
                        onClick={() => {
                            props.setImageNum(i)
                            props.setFullscreenVisible(true);
                        }}
                    />
                </li>
            );
        }
    }

    return(
        <div id="imageGallery">
            <ul>
                {images}
            <li></li>
            </ul>
            <SmallButtons buttons={backButton} location="top right" opacity="0.5" />
        </div >
    );
}

export default ImageGallery;