import React from 'react';
import './Buttons.css';

function SmallButtons(props) {
    const margin = '5px';
    let buttonStyle = {};
    let containerStyle= {};

    //set location based on props
    if (props.top) {
        containerStyle.top = props.top;
    }
    if (props.bottom) {
        containerStyle.bottom = props.bottom;
    }
    if (props.left) {
        containerStyle.left = props.left;
    }
    if (props.right) {
        containerStyle.right = props.right;
    }

    //set location based on props
    if (props.location) {
        switch (props.location) {
            case 'top':
                containerStyle = {
                    top: margin,
                    left: 0,
                    right: 0,
                    justifyContent: 'center',
                    alignItems: 'center'
                }
                break;
            case 'bottom':
                containerStyle = {
                    bottom: margin,
                    left: 0,
                    right: 0,
                    justifyContent: 'center',
                    alignItems: 'center'
                }
                break;
            case 'left':
                containerStyle = {
                    top: 0,
                    bottom: 0,
                    left: margin,
                    justifyContent: 'center',
                    alignItems: 'center'
                }
                break;
            case 'right':
                containerStyle = {
                    top: 0,
                    bottom: 0,
                    right: margin,
                    justifyContent: 'center',
                    alignItems: 'center'
                }
                break;
            case 'top left':
                containerStyle = {
                    top: margin,
                    left: margin,
                    right: 0,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                }
                break;
            case 'top right':
                containerStyle = {
                    top: margin,
                    left: 0,
                    right: margin,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end'
                }
                break;
            case 'bottom left':
                containerStyle = {
                    bottom: margin,
                    left: margin,
                    right: 0,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                }
                break;
            case 'bottom right':
                containerStyle = {
                    bottom: margin,
                    left: 0,
                    right: margin,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end'
                }
                break;
            default:
                containerStyle = {
                    inset: 0
                }
                break;
        }
    }

    //change styles as requested
    buttonStyle.color = props.altColor ? '#FF8CD7' : '#C8C800';
    buttonStyle.border = props.altColor ? '2px solid #C8C800' : '2px solid #FF8CD7';
    buttonStyle.opacity = props.opacity ? props.opacity : 1;
    containerStyle.flexDirection = props.horizontal ? 'row' : 'column';

    const buttons = [];
    if ('id' in props.buttons) {
        buttons.push( 
            <div className="smallButton"
                id={props.buttons.id}
                key={props.buttons.id}
                onClick={()=>props.buttons.onClick(props.buttons.id)}
                style={buttonStyle}
            >
                <span className="material-icons">{props.buttons.icon}</span>
            </div>
        );
    } else if (props.buttons[0]) {
        for (let i in props.buttons) {
            buttons.push( 
                <div className="smallButton"
                    id={props.buttons[i].id}
                    key={'button ' + i}
                    onClick={()=>props.buttons[i].onClick(props.buttons[i].id)}
                    style={buttonStyle}
                >
                    <span className="material-icons">{props.buttons[i].icon}</span>
                </div>
            );
        }
    } else {
        buttons.push(<div>No buttons detected.</div>)
    }


    return (
        <div className="buttonCont" style={containerStyle}>
            {buttons}
        </div>
    );
}

export default SmallButtons;